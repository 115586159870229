<template>
    <select
        v-if="useNativeMenu"
        v-model="localValue"
        :disabled="disabled"
        v-bind="$attrs"
        class="appearance-none"
        :class="nativeMenuClasses"
    >
        <option :value="null">{{ placeholder }}</option>

        <option
            v-for="choice in choices"
            :key="choice[mergedDropdownConfig.valueProp]"
            :value="choice[mergedDropdownConfig.valueProp]"
        >
            {{ choice[mergedDropdownConfig.label] }}
        </option>
    </select>

    <Multiselect
        v-else
        v-model="localValue"
        :options="choices"
        :classes="multiselectClasses"
        :class="{ 'input-is-errored': props.isErrored }"
        :can-deselect="false"
        :can-clear="false"
        :disabled="disabled"
        :placeholder="placeholder"
        :native-support="true"
        v-bind="$attrs"
        :value-prop="mergedDropdownConfig.valueProp"
        :label="mergedDropdownConfig.label"
    >
        <template #caret>
            &nbsp;
            <Icon name="chevron-down" class="multiselect-custom-caret" />
        </template>

        <template #singlelabel="{ value }">
            <div class="flex w-full items-center gap-3">
                <div class="multiselect-selection flex items-center">
                    <slot name="singlelabel" :value="value">
                        {{ value[mergedDropdownConfig.label] }}
                    </slot>
                </div>
            </div>
        </template>

        <template #option="{ option }">
            <slot name="option" :option="option">
                {{ option[mergedDropdownConfig.label] }}
            </slot>
        </template>
    </Multiselect>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import Multiselect from '@vueform/multiselect';
import { DropdownChoices } from '~/types/miscellaneous';

const emit = defineEmits(['update:model-value']);

const props = withDefaults(
    defineProps<{
        modelValue?: any;
        choices: DropdownChoices;
        disabled?: boolean;
        isErrored?: boolean;
        placeholder?: string;
        dropdownConfig?: { [key: string]: any };
        useNativeMenu?: boolean;
    }>(),
    {
        modelValue: undefined,
        disabled: false,
        isErrored: false,
        placeholder: 'Select...',
        dropdownConfig: () => ({}),
        useNativeMenu: false,
    },
);

const localValue = ref(props.modelValue);

watch(
    () => props.modelValue,
    (prop) => {
        localValue.value = prop;
    },
);

watch(localValue, () => {
    emit('update:model-value', localValue.value);
});

const hasSelection = computed(() => {
    return 'object' === typeof props.modelValue
        ? !isEmpty(props.modelValue)
        : props.modelValue !== undefined &&
              props.modelValue !== null &&
              props.modelValue !== '';
});

const nativeMenuClasses = computed(() => {
    return {
        'native-menu-has-selection': hasSelection.value,
        'input-is-errored': props.isErrored,
    };
});

const multiselectClasses = computed(() => {
    return {
        container: hasSelection.value
            ? 'multiselect multiselect-has-selection'
            : 'multiselect',
    };
});

const mergedDropdownConfig = computed(() => {
    return {
        ...{
            label: 'label',
            valueProp: 'value',
        },
        ...props.dropdownConfig,
    };
});
</script>

<style scoped>
select {
    background-image: url('data:image/svg+xml,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.61071 6.8725L0.160709 2.42249C0.0578146 2.31938 2.28882e-05 2.17966 2.28882e-05 2.03399C2.28882e-05 1.88833 0.0578146 1.74861 0.160709 1.64549L0.679707 1.12649C0.782777 1.0239 0.922283 0.966309 1.06771 0.966309C1.21313 0.966309 1.35264 1.0239 1.45571 1.12649L4.99971 4.65349L8.54371 1.12649C8.64678 1.0239 8.78628 0.966309 8.93171 0.966309C9.07713 0.966309 9.21664 1.0239 9.31971 1.12649L9.83871 1.64549C9.9416 1.74861 9.99939 1.88833 9.99939 2.03399C9.99939 2.17966 9.9416 2.31938 9.83871 2.42249L5.38871 6.8725C5.33763 6.9236 5.27699 6.96413 5.21024 6.99179C5.1435 7.01944 5.07196 7.03368 4.99971 7.03368C4.92746 7.03368 4.85592 7.01944 4.78917 6.99179C4.72243 6.96413 4.66178 6.9236 4.61071 6.8725Z" fill="rgb(16 24 32 / 0.6)"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    background-size: 0.9375rem 0.9375rem;
}
</style>
